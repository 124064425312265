import React from 'react';

import { IconBasePropsWithColor } from './IconBase';

import { colors } from 'styles/cp';

const Print: React.FC<IconBasePropsWithColor> = ({
  width = 24,
  height = 24,
  color = colors.white,
  className = '',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      className={className}
      aria-label="Print"
    >
      <g fill="none" fillRule="evenodd">
        <path
          aria-label="Print path"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M7.06 10V4c0-.552.448-1 1-1h8c.552 0 1 .448 1 1v6M7 19H5c-1.105 0-2-.895-2-2v-5c0-1.105.895-2 2-2h14c1.105 0 2 .895 2 2v5c0 1.105-.895 2-2 2h-2m-9.943.016c.002-1.105.898-1.999 2.003-1.997l6 .01c1.105.001 1.999.898 1.997 2.002-.002 1.105-.899 1.999-2.003 1.997l-6-.01c-1.105 0-1.999-.898-1.997-2.002z"
        />
        <circle cx="7.003" cy="13.933" r="1" fill={color} />
      </g>
    </svg>
  );
};

export default Print;
